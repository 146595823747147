import React, { useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
} from "reactstrap";
import EmailEditor from "react-email-editor";

import "./App.css";
import AllTags from './config/tags'
import { RESET_JSON } from './config/config';
import InputModal from './components/InputModal';
import Nav from './components/Nav';

function App() {
  const [fileInfo, setFileInfo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [fileType, setFileType] = useState("");
  const [isReset, setReset] = useState(false);


  const emailEditorRef = useRef(null);
  let fileRef = null;
  const fileReader = new FileReader();

  const exportHtml = () => {

    const editor = emailEditorRef.current?.editor;

    editor.exportHtml(data => {
      const { html } = data;
      setFileInfo(html);
      setFileType("html");
      setShowModal(true);
    });
  };

  const exportJson = () => {

    const editor = emailEditorRef.current?.editor;

    editor.saveDesign(data => {
      setFileInfo(data);
      setFileType("json");
      setShowModal(true);
    });
  };

  const resetDesign = () => {
    try {
      const editor = emailEditorRef.current?.editor;

      editor.loadDesign(JSON.parse(RESET_JSON));
    } catch (err) {
      alert("Unable to reset the canvas. Please refresh.");
    }
  };

  const loadFromFile = e => {
    let files = e.target.files;

    if (!files || files.length !== 1) {
      return alert("Only one file at a time please");
    }

    if (files[0].type !== "application/json") {
      return alert("Only files of type json are allowed");
    }

    fileReader.onloadend = () => {
      let fileData = fileReader.result;
      try {
        const editor = emailEditorRef.current?.editor;
        editor.loadDesign(JSON.parse(fileData));
      } catch (err) {
        alert("Unable to load file. The file is not valid json");
        console.warn(err);
      }
    };

    fileReader.readAsText(files[0]);
  };

  const closeModal = () => {
    setShowModal(false);
    setReset(false);
  };

  return (
    <Container fluid>
      <Nav
        exportHtml={exportHtml}
        exportJson={exportJson}
        fileRef={fileRef}
        loadFromFile={loadFromFile}
        setReset={setReset}
        setShowModal={setShowModal}
      />
      <Row style={{ justifyContent: 'center' }}>

      </Row>
      <Row>
        {
          showModal &&
          <InputModal
            fileInfo={fileInfo}
            fileType={fileType}
            closeModal={closeModal}
            handleReset={resetDesign}
            reset={isReset}
          />
        }
        <Col sm="12" style={{ paddingLeft: 0 }}>

          <EmailEditor
            style={{ height: "1000px" }}
            ref={emailEditorRef}
            options={{
              mergeTags: AllTags,
              fonts: {
                showDefaultFonts: true,
                customFonts: [
                  {
                    label: "So6 Comic Sans",
                    value: "'Comic Sans MS', cursive, sans-serif"
                  },
                  {
                    label: "so6 Lobster Two",
                    value: "'Lobster Two',cursive",
                    url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700"
                  }
                ]
              },
              blocks: [
                {
                  "category": "bienvenue",
                  "tags": ["bienvenue"],
                  "data": {
                    "cells": [1],
                    "columns": [{
                      "contents": [{
                        "type": "image",
                        "values": {
                          "containerPadding": "10px",
                          "_meta": {
                            "htmlClassNames": "u_content_image"
                          },
                          "selectable": true,
                          "draggable": true,
                          "deletable": true,
                          "src": {
                            "url": "https://unroll-images-production.s3.amazonaws.com/projects/3019/1590139895039-earlycook_v1.1_color.png",
                            "width": 1336,
                            "height": 2099
                          },
                          "textAlign": "center",
                          "altText": "Image",
                          "action": {
                            "name": "web",
                            "values": {
                              "href": "",
                              "target": "_blank"
                            }
                          }
                        }
                      }],
                      "values": {
                        "_meta": {
                          "htmlClassNames": "u_column"
                        }
                      }
                    }],
                    "values": {
                      "columns": false,
                      "backgroundColor": "",
                      "columnsBackgroundColor": "",
                      "backgroundImage": {
                        "url": "",
                        "fullWidth": true,
                        "repeat": false,
                        "center": true,
                        "cover": false
                      },
                      "padding": "0px",
                      "hideDesktop": false,
                      "hideMobile": false,
                      "noStackMobile": false,
                      "_meta": {
                        "htmlClassNames": "u_row"
                      },
                      "selectable": true,
                      "draggable": true,
                      "deletable": true
                    }
                  },
                  "displayMode": "email"
                }]
            }}
          />

        </Col>
      </Row>
    </Container>
  );
}

export default App;
