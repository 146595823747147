import React from "react";
import {
  Button,
  Col,
  Row,
} from "reactstrap";

export default function Nav({ fileRef, setReset, setShowModal, exportHtml, exportJson, loadFromFile }) {
  return (
    <Row>
      <Col sm="12">
        <h1 className="title">OIQIA Mail Builder</h1>
        <div className="btn-group">
          <input
            className="input-file"
            size="sm"
            color="info"
            type="file"
            ref={ref => (fileRef = ref)}
            onChange={loadFromFile}
          />
          <Button color="info" size="sm" onClick={() => fileRef.click()}>
            Load From File
            </Button>
          <Button
            className="btn-spacing"
            size="sm"
            color="primary"
            onClick={exportHtml}
          >
            Export HTML
            </Button>
          <Button
            className="btn-spacing"
            size="sm"
            color="secondary"
            onClick={exportJson}
          >
            Export JSON
            </Button>
          <Button
            className="btn-spacing"
            size="sm"
            color="danger"
            onClick={() => {
              setShowModal(true);
              setReset(true);
            }}
          >
            Reset
            </Button>
        </div>
      </Col>
    </Row>
  )
}
