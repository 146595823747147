import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

export default function ResetTemplate({ closeModal, handleReset }) {
  const [isOpen, setIsOpen] = useState(true);

  const _handleReset = () => {
    handleReset();
    toggle();
  };

  const toggle = () => {
    closeModal();
    setIsOpen(!isOpen);
  };

  return (
    <Modal isOpen={isOpen} backdrop toggle={toggle}>
      <ModalHeader toggle={toggle}>Reset template</ModalHeader>
      <ModalBody>
        <p>Are you sure you want to reset the template?</p>
        <Button
          block
          className="saveBtn"
          color="primary"
          onClick={() => toggle()}
        >
          No
          </Button>
        <Button
          block
          className="saveBtn"
          color="danger"
          onClick={_handleReset}
        >
          Yes
          </Button>
      </ModalBody>
    </Modal>
  )
}
