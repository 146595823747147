import React from "react";
import ResetTemplate from './ResetTemplate';
import ExportHtml from './ExportHtml';
import ExportJson from './ExportJsonModal';

function InputModal(props) {

  if (props.reset) return <ResetTemplate {...props} />
  else if (props.fileType === 'json') return <ExportJson {...props} />

  return <ExportHtml {...props} />
}

export default InputModal;