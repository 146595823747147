import React, { useState } from "react";
import { save } from "save-file";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
} from "reactstrap";

export default function ExportJsonModal({ fileInfo, fileType, closeModal, }) {
  const [fileName, setFileName] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  const handleSaveFile = () => {
    if (fileName.length < 1) {
      closeModal();
      return alert("Please enter file name");
    }

    const data = JSON.stringify(fileInfo);

    let blob = new Blob([data], {
      type: fileType === "json" ? "applicatin/json" : "text/plain"
    });

    save(blob, fileName + "." + fileType);

    closeModal();
  };

  const toggle = () => {
    closeModal();
    setIsOpen(!isOpen);
  };


  return (
    <Modal isOpen={isOpen} backdrop toggle={toggle}>
      <ModalHeader toggle={toggle}>Save file as {fileType}</ModalHeader>
      <ModalBody>

        <Input
          type="text"
          placeholder="Enter file name"
          onChange={e => setFileName(e.target.value)}
        />

        <Button
          block
          className="saveBtn"
          color="success"
          onClick={handleSaveFile}
          disabled={fileName.length === 0}
        >
          Save file
        </Button>
      </ModalBody>
    </Modal>
  )
}
